/*
 * Page: Login & Register
 * ----------------------
 */

.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
  a {
    color: #444;
  }
}

.login-page,
.register-page {
  background: @gray-lte;
}

.login-box,
.register-box {
  width: 360px;
  margin: 7% auto;
  @media (max-width: @screen-sm) {
    width: 90%;
    margin-top: 20px;
  }
}

.login-box-body,
.register-box-body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
  .form-control-feedback {
    color: #777;
  }
}

.login-box-msg,
.register-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.social-auth-links {
  margin: 10px 0;
}
